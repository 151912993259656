.top {
  position: absolute;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

#menu-open {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: -1000px;
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;

  background: $white;

  @include px(1rem);

  z-index: 999;
  margin-bottom: 4rem;
  border-bottom: 1px solid #fff;

  // Default style and sticky style
  .top.is-off + & {
    border-color: #999;
  }

  @include breakpoint-up-to(large) {
    border-color: #999;
  }

  .body--black & {
    background: $black;
  }
}

.menu + .main {
  padding-top: 5rem;
}

.menu__logo {
  text-indent: -4000rem;
  background: url(static/img/swissnex.png) no-repeat center;
  background-size: contain;
  width: 10rem;
  display: block;
  @include py(1rem);
}

.menu__menu {
  @include my(1rem);
  display: flex;
}

.menu__menu a {
  @include px(0.25rem);
  color: inherit;
  text-decoration: none;
  margin: 0 0 0 1rem;
  transition: border 0.1s;
  position: relative;
}

.menu__menu a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  right: 50%;
  height: 2px;
  background: transparent;
  transition: 0.2s;
}

.menu__menu a:hover::after,
.menu__menu a.active::after {
  background: $black;
  left: 0;
  right: 0;
}

@include breakpoint-up-to(large) {
  .menu {
  }

  .menu__content {
    display: grid;
    grid-template-areas:
      "logo toggle"
      "menu menu";
    grid-template-columns: 1fr min-content;
  }

  .menu__logo {
    grid-area: logo;
  }

  .menu__toggle {
    grid-area: toggle;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    svg {
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  .menu__menu {
    grid-area: menu;
    flex-flow: column nowrap;
    flex-basis: 100%;
    max-height: 0;
    overflow: hidden;
    @include my(0);

    transition: 0.2s max-height;
  }

  #menu-open:checked + .menu__menu {
    max-height: 500px;
  }

  .menu__menu a {
    display: block;
    @include my(0.5rem);
    @include mx(0);
  }
}

@include breakpoint(large) {
  .menu__toggle {
    display: none;
  }

  .menu__content {
    @include container(large);

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
