/* https://web.dev/one-line-layouts/ */

@use "sass:math";

.cards {
  @include container(large);
  margin-bottom: 1rem;

  /* Fallback */
  display: flex;
  flex-flow: row wrap;

  /* Better browsers */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
}

.card {
  position: relative;

  color: $white;
  text-decoration: none;
  background: color(darkgray);
  transition: 0.25s background;
}

.card-content {
  overflow: hidden;

  display: flex;
  flex-flow: column nowrap;
}

.card-image {
  padding-bottom: math.div(9, 16) * 100%;
  overflow: hidden;
  position: relative;
  background-color: lightgrey;

  .card-img {
    background-size: cover;
    transition: transform 0.25s;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &::before {
    content: "";
    opacity: 0;
    position: absolute;
    z-index: 1;
    background: $black;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: opacity 0.25s;
  }

  &::after {
    @extend %button;

    // background: rgba(0, 0, 10, 0.3);
    background: $white;
    color: $black;

    $xM: 15%;

    content: "discover highlight";
    position: absolute;
    z-index: 2;
    margin: 25% $xM 0 $xM;
    width: (100% - 2 * $xM);
    text-align: center;
    opacity: 0;
    transition: opacity 0.25s;

    .archive & {
      content: "discover more";
    }
  }
}

.card-year {
  background: color(darkgray);
  color: $white;
  font-size: 1.25rem;
  padding: 0.25em 0.5em 0.125em 0.5em;
  line-height: 1;
  border-radius: 0.5em 0 0 0;
  position: absolute;
  bottom: 0;
  right: 0;
}

.card-body {
  flex: 1 0 auto;

  padding: 1rem 0.5rem;
  position: relative;
}

.card-title {
  font-size: 1.15rem;
  line-height: 1.2;
  margin-bottom: 0.25em;
  @include line-clamp(3);
}

.card-excerpt {
  @include line-clamp(4);
  @extend %small;

  margin-bottom: 0.25em;
}

.card .button {
  margin: 0 1rem 1rem 1rem;
  align-self: flex-start;
}

.card:hover {
  .card-img {
    transform: scale(1.1);
  }
  .card-image {
    &::before {
      opacity: 0.4;
    }

    &::after {
      opacity: 1;
      transition: opacity 0.75s;
    }
  }
}

@each $name, $rgb in $colors {
  .card.highlight-#{"" + $name} .card-body::before {
    color: $rgb;
  }
}

.card.is-featured::after {
  content: "";
  background: $white;
  position: absolute;
  top: 0;
  left: 0;
  border: 1rem solid;
  border-color: transparent var(--accent) var(--accent) transparent;
  z-index: 5;
}

.card.is-inverted {
  background: $white;
  color: $black;

  &:hover {
    background: rgba(40, 40, 40, 0.1);
  }
}

.card-slider-container {
  overflow-y: hidden;

  height: 300px;

  @include breakpoint(medium) {
    height: 347px;
  }
}

.card-slider {
  list-style: none;
  display: flex;
  max-width: 100%;
  overflow-x: auto;
  scroll-behavior: smooth;

  height: 100%;
  // TODO: there needs to be an other way to override the reset definition
  padding-bottom: 1rem !important;
  box-sizing: content-box;
}

.card-slider__item {
}

.card-slider .cards {
  display: inline-flex;
  flex-wrap: nowrap;
  max-width: none;

  .card {
    flex: 0 0 320px;
    width: 320px;
    margin-right: var(--gutter);
  }
}

.card-slider-navigation {
  display: inline-flex;
  margin-top: 1rem;
}

.card-slider-navigation__button {
  border-radius: 0;
  border: none;
  padding: 0;
  margin: 0;
  margin-right: 2rem;

  &:disabled {
    opacity: 0.2;
    cursor: default;
  }

  svg {
    display: block;
    width: 2.25rem;
    height: 2.25rem;
  }
}

.card-slider-navigation__button--flip {
  margin-right: 0rem;

  svg {
    transform: rotate(180deg);
  }
}
