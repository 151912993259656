.facts-container {
  @include grid-column(5);
  @include extend-background("#fff");
  @include my(2rem);
  @include py(3rem);

  + .facts-container {
    margin-top: -2rem;
  }
}

.facts {
  @include container(large);

  color: $black;

  display: grid;
  place-items: center;
  gap: 2.5rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.fact {
  text-align: center;
  grid-column: span 2;
  text-decoration: none;
  color: inherit;
}

.fact__text {
  line-height: 1.2;
}

.fact__icon {
  min-height: 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 1rem;
  transition: color 0.2s;
  color: var(--accent);

  svg {
    max-width: 5rem;
  }

  path {
    stroke: currentColor;
  }
}

@include breakpoint(medium) {
  .facts {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  /* Dealing with single orphan */
  .fact:last-child:nth-child(2n - 1) {
    grid-column-end: 4;
  }
}

@include breakpoint(large) {
  .facts {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  /* Dealing with 2 orphan items */
  .fact:last-child:nth-child(3n - 1) {
    grid-column-end: -2;
  }

  .fact:nth-last-child(2):nth-child(3n + 1) {
    grid-column-end: 4;
  }

  /* Dealing with single orphan */
  .fact:last-child:nth-child(3n - 2) {
    grid-column-end: 5;
  }
}

.fact__number {
  font-variant-numeric: tabular-nums;
  font-size: 64px;
  color: $white;
  -webkit-text-stroke: 1px var(--accent);
  text-stroke: 1px var(--accent);
  transition:
    color 0.2s,
    text-stroke 0.2s,
    -webkit-text-stroke 0.2s;
  line-height: 1;
  margin-bottom: 0.5rem;
}

.fact:hover .fact__number {
  color: var(--accent);
}

.facts-container--red {
  @include extend-background("var(--accent)");
  display: grid;
  gap: 2rem;

  @include breakpoint-up-to(medium) {
  }

  @include breakpoint(medium) {
    grid-template-columns: 700fr 600fr;
    gap: 0;
    margin: 0 -1rem 1rem 1rem;
  }

  @media (screen and min-width: #{map-get($widths, large) + 100px}) {
    margin: 0 -6rem 1.5rem -2rem;
  }
}
