.terms--margin {
  @include my(3rem);
  @extend %column-full-span;
}

.terms-wrapper {
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
}

.terms {
  display: grid;

  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: repeat(2, 7.5rem);

  position: relative;
  text-decoration: none;
  color: currentColor;

  @include breakpoint-up-to(medium) {
    grid-template-rows: repeat(2, 5rem);
  }
}

.terms .term {
  display: grid;
  align-content: center;

  padding-left: 2rem;
  padding-right: 2rem;

  color: $white;
  font-size: 2rem;
  font-size: clamp(1.5rem, 3vmin, 4rem);
  line-height: 1;

  transition: 0.25s background;

  &.second {
    text-align: right;
  }
}

.terms__explore-more {
  grid-row: 1 / 3;
  position: absolute;
  place-self: center;
  background: white;
  color: $black;
  text-align: center;
  font-size: 85%;
  margin: 0;
  padding-left: 3em;
  padding-right: 3em;
  outline: 0;

  @include breakpoint(medium) {
    font-size: 1rem;
  }

  .terms:hover & {
    opacity: 0.47;
  }
}

.terms__random {
  align-self: flex-end;
  text-align: right;
  font-size: 1rem;
  line-height: 1;

  $icon-size: 1.3125rem;

  background: url(static/img/reload.svg) top 0.25rem left 0 / #{$icon-size} no-repeat;
  border: none;
  border-radius: 0;

  padding: 0.5rem 0 0.5rem $icon-size + 0.5rem;
  margin: 0;
  margin-top: 0.5rem;

  @include breakpoint-up-to(medium) {
    font-size: 0.875rem;
  }
}
