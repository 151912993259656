.report {
  position: sticky;
  z-index: 900;
  top: 150px;
  right: 0;
}

.report__link {
  position: absolute;
  right: 0;
  width: 3rem;

  display: block;
  color: var(--accent);

  background: $white;
  transition: background 0.2s;

  // border: 1px solid $black;
  // box-shadow: 0 0 3px 0 rgba(0, 0, 30, 0.3);
  // &:hover { background: #eee; }
}

@include breakpoint-up-to(large) {
  .report__link {
    width: 2.25rem;
  }
}

@include breakpoint-up-to(medium) {
  .report {
    top: 75px;
  }

  .report__link {
    width: 1.5rem;
    right: -1rem;
  }
}
