// $ch-bg: #575757;
$ch-bg: color(darkgray);
$ch-red: #ed1b24;

.footer {
  background: $ch-bg;
  color: $white;
  margin-top: 3rem;
  padding-bottom: 5rem;

  .footer-content {
    @include container(large);

    border-top: 6px solid $ch-red;
    padding-top: 2rem;

    background-image: url(static/img/swissnex-seri-logo.png);
    background-repeat: no-repeat;
    background-size: 17rem;
    background-position: 1rem 2rem;

    display: flex;

    @include breakpoint-up-to(medium) {
      padding-top: 15rem;
      padding-left: 1rem;
    }

    @include breakpoint(medium) {
      flex-flow: row nowrap;
      height: 15rem;

      > * {
        display: block;
        width: 40%;
      }
    }
  }

  a {
    display: block;
    color: $white;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }

    // &.active {
    //   color: color(purple);
    // }
  }
}

.footer-realized {
  a {
    max-width: 10rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
}
