@mixin grid() {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 0 var(--gutter, 1rem);
  width: 100%;
  max-width: map-get($widths, large);
  margin-left: auto;
  margin-right: auto;
}

@mixin grid-column($num-of-columns: 12, $breakpoint: null) {
  @if $breakpoint {
    @include breakpoint($breakpoint) {
      grid-column-end: span $num-of-columns;
    }
  } @else {
    grid-column-end: span $num-of-columns;
  }
}

@mixin grid-column-offset($offset, $breakpoint: null) {
  @if $breakpoint {
    @include breakpoint($breakpoint) {
      grid-column-start: $offset + 1;
    }
  } @else {
    grid-column-start: $offset + 1;
  }
}

.grid {
  @include grid();
}

%column-full-span,
.column-full-span {
  grid-column: 1 / -1;
}
