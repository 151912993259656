.years {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  z-index: 1002;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5rem 1rem;
  max-width: 100vw;

  pointer-events: none;
}

.years > * {
  pointer-events: auto;
}

.years__year {
  background: var(--accent);
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  display: block;
  padding: 0.75rem 1.5rem;
  border-radius: 2rem;
  line-height: 1;

  box-shadow: 4px 4px 24px 0 rgba(0, 0, 30, 0.15);
  transition: background 0.2s;

  &.active {
    background: $black;
  }

  @media screen and (max-width: 46rem) {
    font-size: 1rem;
    padding: 0.5rem 1rem;
  }
}

.years__arrow {
  display: grid;
  place-items: center;

  &[disabled] {
    pointer-events: none;
    opacity: 0.3;
  }

  & svg {
    width: 2em;
    height: 2em;
  }

  @media screen and (max-width: 46rem) {
    display: none;
  }
}

.years__arrow--next {
  margin-right: auto;
}

.years__arrow--previous {
  margin-left: auto;

  & svg {
    transform: rotate(180deg);
  }
}
