@charset "utf-8";

@import "scss/_reset.scss";
@import "scss/_containers.scss";

@import "scss/_utils.scss";
@import "scss/_colors.scss";
@import "scss/_mixins.scss";
@import "scss/_typography.scss";
@import "scss/_grid.scss";

// @import "scss/_foundation.scss";

@import "scss/_cards.scss";
@import "scss/_terms.scss";
@import "scss/_topics.scss";
@import "scss/_footer.scss";
@import "scss/_menu.scss";
@import "scss/_facts.scss";
@import "scss/_report.scss";

@import "scss/_plugins.scss";
@import "scss/_admin.scss";

@import "scss/_quotations.scss";
@import "scss/_inverted.scss";

@import "scss/_years.scss";

:root {
  --gutter: 1rem;
}

body {
  display: flex;
  flex-flow: column nowrap;
  overflow-x: hidden;

  // background: $black;
  // color: $white;

  // background-image: url("img/2020.svg");
  // background-repeat: no-repeat;
  // background-position: right 10px top 70%;
  // background-attachment: fixed;
}

body > * {
  flex-shrink: 0;
}

.main {
  flex: 1 0 auto;
  @include px(1rem);
}

.backlink {
  @extend %column-full-span;
  margin-top: 2.25rem;
  margin-bottom: 1.25rem;
  padding-left: var(--gutter);
  padding-right: var(--gutter);

  display: flex;
  justify-content: space-between;

  // TODO:  Large breakpoint is not precise enough in this case
  //        maybe solve it the same way as the main element applies the gutter.
  @include breakpoint(large) {
    padding-left: 0;
    padding-right: 0;
  }
}

.backlink__link {
  color: inherit;

  display: inline-flex;
  align-items: center;
  margin-right: 0.5rem;

  font-weight: bold;

  span {
    text-decoration: underline;
  }

  svg {
    flex: 0 0 2.25rem;
    width: 2.25rem;
    margin-right: 1rem;
  }
}

.backlink__link.flip {
  text-align: right;
  margin-left: 0.5rem;
  margin-right: 0;

  svg {
    margin-left: 1rem;
    margin-right: 0;
  }
}

.show-all {
  display: block;
  margin: 2rem auto 4rem auto;
  color: var(--accent);
  text-align: center;
  text-transform: uppercase;
  font-size: 13px;
}
