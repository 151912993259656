/*
@font-face {
  font-family: "Apax";
  src: url("lib/Apax-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: "Apax";
  src: url("lib/Apax-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}
*/

@font-face {
  font-family: "CircularXX";
  src: url("static/lib/CircularXX-Light.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: "CircularXX";
  src: url("static/lib/CircularXX-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: "CircularXX";
  src: url("static/lib/CircularXX-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}
/*
@font-face {
  font-family: "SimplonNorm";
  src: url("static/lib/SimplonNorm-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}
*/

body {
  font-family: "CircularXX", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.6;
}

%headline-base {
  margin-bottom: 1rem;
  line-height: 1.2;
  font-weight: bold;
}

%headline-1 {
  --indent: 0.73em;
  @extend %headline-base;
  font-size: 2rem;
  line-height: 1;
  letter-spacing: 0.03em;
  margin-top: 2rem;

  text-indent: calc(var(--indent) * -1);
  padding-left: var(--indent);
  max-width: 8ch;

  @include breakpoint(medium) {
    font-size: 67px;
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
}

%headline-2 {
  @extend %headline-base;
  font-size: 1.3rem;
  letter-spacing: 0.02em;
  margin-top: 2rem;
  margin-bottom: 1.25rem;

  @include breakpoint(medium) {
    font-size: 2.75rem;
    margin-top: 4rem;
    margin-bottom: 2.5rem;
  }
}

%headline-3 {
  @extend %headline-base;
  font-size: 1.15rem;
  letter-spacing: 0.02em;
  margin-top: 1rem;

  @include breakpoint(medium) {
    font-size: 18px;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
}

%headline-4 {
  @extend %headline-base;
  font-size: 1rem; // Reset font size
  letter-spacing: 0.02em;
}
