%button {
  border: 1px solid $black;
  display: inline-block;
  vertical-align: middle;
  font-size: inherit;
  user-select: none;
  line-height: 1.5;

  text-decoration: none;
  padding: 0.35em 1.2em;
  border-radius: 999px;
  margin: 0 1rem 1rem 0;
  margin-right: 1rem;

  color: $black;
  background: transparent;
  cursor: pointer;

  transition: 0.25s opacity;

  &:hover {
    opacity: 0.47;
  }
}

%sr-only,
.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

%small {
  font-size: 85%;
}

@mixin px($length) {
  padding-left: $length;
  padding-right: $length;
}

@mixin py($length) {
  padding-top: $length;
  padding-bottom: $length;
}

@mixin mx($length) {
  margin-left: $length;
  margin-right: $length;
}

@mixin my($length) {
  margin-top: $length;
  margin-bottom: $length;
}

@mixin line-clamp($lines) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: $lines;
  line-clamp: $lines;
}

@mixin extend-background($color) {
  $width: 100vw;
  background: #{$color};
  position: relative;
  transition: 0.2s background;

  &::before,
  &::after {
    content: "";
    position: absolute;
    background: #{$color};
    top: 0;
    bottom: 0;
    width: $width;
    transition: 0.2s background;
  }

  &::before {
    left: -$width;
  }

  &::after {
    right: -$width;
  }
}
