.edit-tools {
  position: fixed;
  z-index: 10;
  right: 0.5rem;
  bottom: 4.5rem;
  background: purple;
  display: flex;
  place-items: center;
  gap: 4px;
  font-size: 0.9rem;
  color: $white;
  padding: 4px;

  &,
  a {
    border-radius: 4px;
  }

  a {
    color: inherit;
    display: inline-block;
    text-decoration: underline;
    border: 1px solid $white;
    padding: 2px 4px;
  }

  a:hover {
    opacity: 0.8;
  }
}
