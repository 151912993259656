.inverted {
  background: $black;
  color: $white;

  @include px(1rem);
  @include py(4rem);

  .content.is-dateline {
    display: none;
  }
}

.inverted + .footer {
  margin-top: 0;
}

.body--black {
  background: $black;
  color: $white;
}
