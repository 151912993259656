$black: #020303;
// $black: #000;
$white: #fff;
$gray: #999;
$lines: 1px solid #999;

:root {
  --accent: #52bfb0;
}

$colors: (
  darkgray: #353534,
  red: #d2595c,
  yellow: #f2e15a,
  purple: #a78aba,
  green: #90c4ab,
  blue: #8195c7,
  ocean: #2a9c9b,
);

@function color($which) {
  @return map-get($colors, $which);
}

@each $name, $rgb in $colors {
  .color-#{"" + $name} {
    color: $rgb !important;
  }
  .bg-#{"" + $name} {
    background-color: $rgb !important;
    color: color-contrast($rgb, $black, $white) !important;
  }
}
