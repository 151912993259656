@use "sass:math";

.content {
  // @include container(large);
  @include grid-column(5);
  position: relative;
}

.grid {
  .is-highlighted {
    span {
      display: inline;
      color: $black;
      background-color: color(green);

      box-shadow:
        -5px -2px 0 3px color(green),
        5px -2px 0 3px color(green);
      box-decoration-break: clone;
      line-height: 1.8;
    }

    a {
      color: $white;
    }
  }

  figcaption {
    @extend %small;
    padding-top: 0.75rem;
    text-align: left;
  }
}

.content-title {
  @extend %headline-1;
  @extend %column-full-span;

  text-indent: 0;
  max-width: none;
  padding-left: 0;
}

@each $name, $rgb in $colors {
  .highlight-#{"" + $name} .content.is-highlighted span {
    // $bg: lighten($rgb, 10%);
    $bg: $rgb;
    background-color: $bg;
    box-shadow:
      -5px -2px 0 3px $bg,
      5px -2px 0 3px $bg;
  }

  .highlight-yellow .content.is-highlighted a {
    color: mix(#1e1e1e, #fff800, 60%);
  }
}

.content.is-richtext {
  margin-top: 1rem;

  & + .content.is-richtext,
  & + .content-title {
    margin-top: 0;
  }
  // @include extend-background("#fff");
  // color: $black;
  // @include py(1rem);

  @include grid-column(4);
  @include grid-column-offset(1);

  // TODO: What was the purpose of that block?
  // @include breakpoint(large) {
  //   position: relative;
  //   left: -1rem;
  // }

  @include breakpoint(large) {
    &.flush-left {
      @include grid-column(3);
    }
  }

  h1 {
    @extend %headline-1;
    position: relative;
    // Align at the left edge of the grid
    left: calc(-1 * (((100% - 3 * var(--gutter)) / 4) + var(--gutter)));
  }

  h2 {
    @extend %headline-2;
  }

  h3 {
    @extend %headline-3;
  }

  h4,
  h5,
  h6 {
    @extend %headline-4;
  }

  a {
    text-decoration: underline;
    color: inherit;
    transition: 0.25s opacity;

    &:hover,
    &:focus {
      opacity: 0.47;
    }
  }

  & > p {
    margin-bottom: 1rem;
  }
}

.content.is-image {
  @include my(2rem);
}

.richtext {
  @include grid-column(4);
  @include grid-column-offset(1);
}

.content.is-images {
  @include my(2rem);

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  gap: 1rem;

  * {
    margin: 0;
  }
}

.content.is-images > figure {
  flex: 1;
}

.content.is-images figcaption {
  text-align: center;
}

.content.is-external {
  @include my(2rem);

  display: flex;
  justify-content: center;

  > * {
    @include mx(auto);
  }
}

.event {
  @include breakpoint(large) {
    float: right;
    width: 16em;
    z-index: 1;
    margin: -20rem -1rem 0 0;
    position: relative;
  }

  @include breakpoint(xlarge) {
    position: sticky;
    margin-top: -15rem;
    margin-right: -5rem;
    top: 80px;
  }
}

h1.title {
  margin-top: 0 !important;
}

button,
.button {
  @extend %button;
}

.responsive-embed {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: math.div(9, 16) * 100%;
  overflow: hidden;

  iframe,
  object,
  embed,
  video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.sharing-buttons {
  @extend %column-full-span;
  display: flex;
  align-items: center;
  @include my(1rem);
}

.sharing-button {
  display: block;
  width: 2rem;
  height: 2rem;
  color: inherit;
  border-radius: 999px;
  border: 1px solid;
  background-position: center center;
  background-size: 70%;
  background-repeat: no-repeat;
  margin-right: 0.5rem;

  transition: opacity 0.25s;

  &:hover {
    opacity: 0.47;
  }

  &.is-website,
  &.is-email {
    border: none;
    width: 4rem;
    height: 4rem;
    background-size: 80%;
    border-radius: 0;
    margin: 0;
  }

  &.is-website {
    background-image: url("static/img/website.svg");
  }
  &.is-email {
    background-image: url("static/img/mail.svg");
  }
}

.content.is-dateline {
  @extend %small;
  color: $gray;

  @include container(medium);

  @include breakpoint(large) {
    position: relative;
    left: -1rem;
  }
}

.content.partners {
  @include extend-background("#fff");
  @include my(3rem);
}

.partners__grid {
  display: grid;
  place-items: center;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));

  @include breakpoint(medium) {
    gap: 2.5rem;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }
}

.partner {
  display: grid;
  place-items: center;
  min-height: 4rem;
  filter: grayscale(1);
  transition: filter 0.2s;

  @include breakpoint(medium) {
    min-height: 6rem;
  }

  &:hover {
    filter: none;
  }
}

.content.continents {
  h1 {
    @extend %headline-1;
    text-align: center;
  }

  .card-image::after {
    content: "explore location";
  }
}

.continents__description {
  text-align: center;
}

.continent {
  cursor: pointer;
  transition: 0.2s;
  stroke: $black;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 30;
  fill: white;
}

.continent.active,
.continent:hover {
  fill: $black;
}

.continent-title {
  @extend %headline-2;
  margin-top: 0;
  margin-right: calc(var(--gutter) * 2);

  display: inline-block;
  position: sticky;
  left: 0;
}

.continent-locations {
  margin-top: -1rem;

  margin-left: calc(var(--gutter) * -1);
  margin-right: calc(var(--gutter) * -1);

  @include breakpoint(medium) {
    margin-top: -2rem;
  }

  @include breakpoint(large) {
    margin-top: -4rem;
  }

  // TODO: there is still a glitch "between" the breakpoints
  // TODO: use variables instead of "random" numbers
  @media screen and (min-width: 62rem) {
    margin-left: calc(-50vw + 30rem);
    margin-right: calc(-50vw + 30rem);
  }

  .card-slider {
    padding-left: var(--gutter);
    padding-right: var(--gutter);

    @media screen and (min-width: 62rem) {
      padding-left: calc(50vw - 30rem);
      padding-right: calc(50vw - 30rem);
    }
  }

  .card-slider-navigation {
    margin-left: var(--gutter);

    @media screen and (min-width: 62rem) {
      margin-left: calc(50vw - 30rem);
    }
  }

  .cards {
    margin-bottom: 0;
  }
}

.blow-up {
  width: 100%;
}
