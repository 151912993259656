.quotation {
  @include grid-column(5);
  margin: 4rem auto;
  max-width: 40rem;
  max-width: min(40rem, 80%);
  line-height: 1.4;

  display: grid;

  @include breakpoint-up-to(medium) {
    grid-template-areas:
      "quote quote"
      "image bla"
      "image name"
      "image function";
    grid-template-columns: 160px 1fr;
    grid-template-rows: min-content 1fr min-content min-content;
    column-gap: 1rem;
  }

  @include breakpoint(medium) {
    grid-template-areas:
      "image quote"
      "name quote"
      "function quote";
    column-gap: 3rem;
    grid-template-columns: 160px 1fr;
    grid-template-rows: min-content min-content 1fr;
  }
}

.quotation__quotation {
  // quotes: "„" "“";
  font-size: 18px;
  // font-weight: bold;
  line-height: 1.55;
  grid-area: quote;

  @include breakpoint-up-to(medium) {
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
  }

  a {
    color: inherit;
  }
}

.quotation__head {
  grid-area: image;
  border-radius: 999px;
}

.quotation__name,
.quotation__function {
  font-size: 13px;
}

.quotation__name {
  grid-area: name;
  margin-top: 0.5rem;
  padding-top: 1.25rem;
  position: relative;
}

.quotation__function {
  font-weight: 100;
}

@include breakpoint(medium) {
  .quotation__name::before {
    content: "";
    background: var(--accent);
    height: 2px;
    position: absolute;
    top: 0;
    left: 1.25rem;
    right: 1.25rem;
  }
}

.quotation__function {
  grid-area: function;
}

.quotation--highlighted {
  @include extend-background("var(--accent)");
  color: #fff;
  padding: 4rem 0 3rem 0;
  max-width: none;

  .quotation__name {
    padding-top: 0.75rem;
  }

  .quotation__name::before {
    display: none;
  }
}
