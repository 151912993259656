/* MEDIA QUERIES */
@use "sass:math";

@function remToPx($rem) {
  @return math.div($rem, 1rem) * 16px;
}

$widths: (
  medium: remToPx(40rem),
  large: remToPx(60rem),
  xlarge: remToPx(100rem),
);

@mixin container($which) {
  max-width: map-get($widths, $which);
  @include mx(auto);
}

@mixin breakpoint-up-to($which) {
  @media (screen and max-width: #{map-get($widths, $which) - 1px}) {
    @content;
  }
}

@mixin breakpoint($which) {
  @media (screen and min-width: #{map-get($widths, $which)}) {
    @content;
  }
}
